import React, { FC } from "react";
import {
  SmsFailed as FeedbackIcon,
  Error as DefenceIcon,
  Done
} from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import classnames from "classnames";
import useStyles from "./styles";
import type { MotificationType } from "./types";

const infoSettings = {
  color: "primary",
  icon: <FeedbackIcon />,
};

const errorSettings = {
  color: "secondary",
  icon: <DefenceIcon />,
};

const warningSettings = {
  color: "warning",
  icon: <DefenceIcon />,
};


const successSettings = {
  color: "success",
  icon: <Done />,
};



export const Notification: FC<MotificationType> = ({ type, message }) => {
  var classes = useStyles();
  var theme = useTheme();
  let settings;

  switch (type) {
    case "info":
      settings = infoSettings;
      break;
    case "error":
      settings = errorSettings;
      break;
    case "warning":
      settings = warningSettings;
      break;
    case "success":
      settings = successSettings;
      break;
    default:
      settings = infoSettings;
      break;
  }

  return (
    <div
      className={classnames(classes.notificationContainer, 
              classes.notificationContained)}
      style={{
        backgroundColor:
          theme.palette[settings.color].main,
      }}
    >
      <div
        className={classnames(classes.notificationIconContainer, 
                classes.notificationIconContainerContained, 
                classes.notificationIconContainerRounded)}
      >
        {settings.icon}
      </div>
      <div className={classes.messageContainer}>{message}</div>
    </div>
  );
};

