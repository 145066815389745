

export class FlightCorridorAPI {

    constructor(private axios) { };

    buildFlightCorridor = ( data: any ) => {
        return this.axios.post('flights/build_flight_corridor/', data 
                            ).then(
                                response => response &&response.data
                            )
    }

    getDefaultUserLocation = () => {
        return this.axios.get('get_autodetect_location/',
                            ).then(
                                response => response &&response.data
                            )
    }

}