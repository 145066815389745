import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    minWidth: "400px", /* or theme.breakpoints.values.sm */
    overflowX: "hidden",
  },
  stopScrolling: {
      height: "100%",
      overflow: "hidden"
  },
  scrollablePlace: {
      height: "1000px"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    '&:not(:first-child)': {
      paddingLeft: 15
    }
  },
  topSideBar: {
    maxWidth: 600,
    position: "relative"
  },
  globalLoading: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  controlBlock: {
    marginTop:30,
    marginBottom:25
  }
}));
