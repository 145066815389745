import React from "react";
import {
  AppBar,
  Toolbar,
} from "@material-ui/core";
import useStyles from "./styles";
import { Typography } from "components/common/wrappers";

export default function Header(props) {
  var classes = useStyles();

  return (
    <AppBar position="relative" className={classes.appBar} id={props.id}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logotype}>       
        </div>
        <Typography variant="h6" weight="medium" className={classes.title}>
          Flight Route Planner
        </Typography>
        <div className={classes.grow} />
      </Toolbar>
    </AppBar>
  );
}
