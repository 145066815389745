import React, { FC } from "react";
import { Route } from "react-router-dom";

//import { AutoSearchPageTest } from "../flight-option-test";
import { FlightCorridorPage } from "../flight-corridor-page";

export const PrivateRoute: FC<any> = ({ component: Component, ...rest }) => {
    //const isLogged = useSelector(successSelector);

    //if (!isLogged && window.location.pathname !== '/login') {
    //    dispatch(setRedirectUrl(window.location.pathname))
    //}

    return (
        <Route {...rest} render={(props) => (
             <Component {...props} />
        )} />
    )
}

export const Routes: FC = () => {
    return (
        <>
            <PrivateRoute path="/app/flight-corridor" 
                component={FlightCorridorPage} 
            >
            </PrivateRoute >
        </>
    );
}

