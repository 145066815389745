import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { appReducer } from "reducers/app-slice";
import { flightCorridorReducer } from "reducers/flight-corridor-slice";

const rootReducer = combineReducers({
	app: appReducer,
	flightCorridor: flightCorridorReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware => {
		if (process.env.NODE_ENV === 'development') {
			const { logger } = require(`redux-logger`);

			return getDefaultMiddleware().concat(logger);
		}

		return getDefaultMiddleware();
	},
	devTools: process.env.NODE_ENV === 'development'
});