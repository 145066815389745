import React, { FC, useEffect } from 'react';
import { Switch, Router } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Themes from "../themes";
import { ThemeProvider } from "@material-ui/styles";
import { MapPageLayout } from 'components/layout/map-page-layout';
import { ToastNotification } from 'components/common/notifications';
import { history } from '../history';
import { checkMock } from 'api';
import { PrivateRoute } from 'components/layout/routes';
//import { ChatModal } from 'components/chat';

export const App: FC = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    checkMock();
  }, [dispatch])

  return (

    <ThemeProvider theme={Themes.default}>
      <Router history={history}>
        
        <ToastNotification />
        <Switch>
          <PrivateRoute path="/" component={MapPageLayout} exact />
          <PrivateRoute path="/app/" component={MapPageLayout} />
        </Switch>
      </Router>
    </ThemeProvider>

  );
}

