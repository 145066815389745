import { createSlice } from "@reduxjs/toolkit";
import type { AppState } from "store";

export type InitialState = {
  showFullSidebar: boolean;
  globalLoading: boolean;
  pageContentLoading: boolean,
  title: string,
};

const initialState: InitialState = {
  showFullSidebar: false,
  globalLoading: true,
  pageContentLoading: false,
  title: 'title here',
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setGlogalLoading: (state, { payload }) => {
      state.globalLoading = payload;
    },

    setShowFullSidebar: (state, { payload }) => {
      state.showFullSidebar = payload;
    },

    setPageContentLoading: (state, { payload }) => {
      state.pageContentLoading = payload;
    }

  },
  extraReducers: {},
});

export const { setGlogalLoading, 
            setShowFullSidebar, 
            setPageContentLoading } = app.actions;

export const globalLoadingSelector = (store: AppState) => 
              store.app.globalLoading;
export const showFullSidebarSelector = (store: AppState) => 
              store.app.showFullSidebar;
export const pageContentLoadingSelector = (store: AppState) => 
              store.app.pageContentLoading;
export const titleSelector = (store: AppState) => 
              store.app.title;


export const appReducer = app.reducer;
