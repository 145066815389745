import { createSlice } from "@reduxjs/toolkit";
import { } from "module";
import type { AppState } from "store";
import { getApi } from "api";
import { setGlogalLoading } from "./app-slice";
import {
    transformCityFromRequest, 
    transformAirportFromRequest } from "utils";

export type Country ={
    id: number,
    name: string,
    codeISO2: string | null
}

export type CountryFromResponse ={
    el_country_dict_id: number,
    name: string,
    code_iso2: string | null
}

export type City = {
    id: number,
    name: string,
    country: Country,
    geo_coord: GeoPoint
}

export type CityFromResponse = {
    el_city_dict_id: number,
    name: string,
    country: CountryFromResponse,
    geo_coord: GeoPoint
}


export type GeoPoint = {
    lat: number,
    lon: number
}

export type Airport = {
    id: number,
    icao: string,
    iata: string,
    name: string,
    city: City,
    geo_coord: GeoPoint
}

export type AirportFromResponse ={
    el_airport_dict_id: number,
    name: string,
    icao_code: string,
    iata_code: string,
    geo_coord: GeoPoint,
    city: CityFromResponse
}

export type Location = {
    name: string,
    airport: Airport | null
    city: City | null,
    geo_coord: GeoPoint | null,
    errorHelperText: string | null
}

export type LocationFromResponse = {
    name: string,
    airport: AirportFromResponse | null
    city: CityFromResponse | null,
    geo_coord: GeoPoint | null
}

export type Route = {
    origin: Location, 
    destination: Location,
    radius_km: number
}

export type RouteFromResponse = {
    origin: LocationFromResponse, 
    destination: LocationFromResponse,
    radius_km: number
}

export type LocationDetectionFromResponse = {
    is_okay: boolean, 
    data: {
        error: any,
        location: Location
    }
}


export type FlightCorridorFromResponse = {
    route: RouteFromResponse,
    found_airports: AirportFromResponse[]
    is_okay: boolean, 
    data: {
        geo: any
    },
    explanation: string
}

export type InitialState = {
    route: Route | null,
    isFlightCorridorLoading: boolean,
    validationError: string,
    validationSuccess: string,
    error_radius: string,
    hoveredStateId: Number | null,
    submitButtonEnabled: boolean,
    initialOrigin: Location | null,
    geomap_data: any,
    resizeCount: Number,
    autolocation: Location | null
}

const initialState: InitialState = {
    route: null,
    isFlightCorridorLoading: false,
    validationError: '',
    validationSuccess: '',
    error_radius: '',
    hoveredStateId: null,
    submitButtonEnabled: false,
    initialOrigin: null,
    geomap_data: null,
    resizeCount: 0,
    autolocation: null
}

/*
enum FieldName {
    radius = "radius",
    origin = "origin",
    destination = "destination"
}
*/


const parseTextFieldLoc = (_payload) => {
    let location = {} as Location;

    if (_payload.airport) {
        location.airport = 
            transformAirportFromRequest(_payload.airport)
        location.name = _payload.airport.name + ' (' + 
            _payload.airport.icao_code + ')'
    }
    if (_payload.city) {
        location.city = 
            transformCityFromRequest(_payload.city)
        location.name = _payload.city.name + ', ' + 
            _payload.city.country.name
    }

    return location;
};


const flightCorridorSlice = createSlice({
    name: "corridor-calculation",
    initialState,
    reducers: {
        setFlightCorridorLoading: (state, { payload }) => {
            state.isFlightCorridorLoading = payload;
        },
        setRouteFormError: (state, { payload }) => {
            state.validationError = payload;
        },
        setRouteFormSuccess: (state, { payload }) => {
            state.validationSuccess = payload;
        },
        setOrigin: (state, { payload }) => {
            if (state.route == null) {
                state.route = {} as Route;
            }

            state.route.origin = parseTextFieldLoc(payload)

            if (state.route && state.route.radius_km && 
                state.route.destination && state.route.origin) {
                    if (Object.keys(state.route.origin).length > 0 && 
                            Object.keys(state.route.destination).length > 0 && 
                            state.route.radius_km && 
                            state.route.radius_km > 0 ) {
                        state.submitButtonEnabled = true
                    }
            }
        },
        _setAutodetectedLocation: (state, { payload }) => {
            state.autolocation = parseTextFieldLoc(payload)
        },
        setDestination: (state, { payload }) => {
            if (!state.route) {
                state.route = {} as Route;
            }
            let d =  {} as Location;
            if (payload.airport) {
                d.airport = 
                    transformAirportFromRequest(payload.airport)
            }
            if (payload.city) {
                d.city = 
                    transformCityFromRequest(payload.city)
            }

            state.route.destination = parseTextFieldLoc(payload)

            if (state.route && state.route.radius_km && 
                    state.route.destination && state.route.origin) {
                if (Object.keys(state.route.origin).length > 0 && 
                        Object.keys(state.route.destination).length > 0 && 
                        state.route.radius_km && state.route.radius_km > 0 ) {
                    state.submitButtonEnabled = true
                }
            }
        },
        unsetOrigin: (state, { payload }) => {
            if (state.route) {
                state.route.origin = {} as Location;
            }
            state.submitButtonEnabled = false
        },
        unsetDestination: (state, { payload }) => {
            if (state.route) {
                state.route.destination = {} as Location;
            }
            state.submitButtonEnabled = false
        },
        setRadius: (state, { payload }) => {
            if (!state.route) {
                state.route = {} as Route;
            }
            state.route.radius_km = payload;
            if (state.route && state.route.radius_km && 
                    state.route.destination && state.route.origin) {
                if (Object.keys(state.route.origin).length > 0 && 
                        Object.keys(state.route.destination).length > 0 && 
                        state.route.radius_km && state.route.radius_km > 0 ) {
                    state.submitButtonEnabled = true
                }
            }
        },
        validateValues: (state, {payload}) => {

        },
        _setGeoMapData: (state, {payload}) => {
            state.geomap_data = payload;
        },
        _setErrorRadius: (state, {payload}) => {
            state.error_radius = payload;
        },
        _setSubmitButton: (state, {payload}) => {
            state.submitButtonEnabled = payload
        },
        _setInitialOrigin: (state, {payload}) => {
            state.initialOrigin = payload
        },
        _resizeMap: (state, {payload}) => {
            state.resizeCount = Number(state.resizeCount) + 1
        },
    },
    extraReducers: {}
})
export const { setFlightCorridorLoading,
                setRouteFormError, 
                setRouteFormSuccess,
                setOrigin,unsetOrigin,setDestination,unsetDestination,
                _setGeoMapData, setRadius,validateValues,
                _setErrorRadius, _resizeMap, 
                _setSubmitButton,_setInitialOrigin,
                _setAutodetectedLocation } = 
                                flightCorridorSlice.actions;

export const setLocation = (location_type:String,v:Location) => async (
    dispatch
) => {
    if (location_type === 'destination') {
        dispatch(setDestination(v));
    }
    if (location_type === 'origin') {
        dispatch(setOrigin(v));
    }
}

export const unsetLocation = (location_type:String, v:Location) => async (
    dispatch
) => {
    if (location_type === 'destination') {
        dispatch(unsetDestination(v));
    }
    if (location_type === 'origin') {
        dispatch(unsetOrigin(v));
    }
}

export const setDefaultUserLocation = () => async (
    dispatch
) => {
    dispatch(setGlogalLoading(true));
    const response: LocationDetectionFromResponse | undefined = 
            await getApi.FlightCorridorAPI.getDefaultUserLocation();
    if (response && response.data && response.data.location) {
        let loc = response.data.location;
        dispatch(setOrigin(loc));
        dispatch(_setAutodetectedLocation(loc));
   
        //dispatch(_setInitialOrigin(loc));

    }
    dispatch(setGlogalLoading(false));
}

export const setInitialOrigin = (loc) => async (
    dispatch
) => {
    dispatch(_setInitialOrigin(loc));
}

export const setRadiusValue = (v:Number | null) => async (
    dispatch
) => {
    dispatch(setRadius(v));
}

export const setErrorRadius = (payload) => async (
    dispatch
) => {
    dispatch(_setErrorRadius(payload))
}

export const resizeMap = () => async (
    dispatch
) => {
    dispatch(_resizeMap(true))
}

export const setSubmitButton = (isEnabled) => async (
    dispatch
) => {
    dispatch(_setSubmitButton(isEnabled))
}

export const buildFlightCorridor = (route: Route | null) => async (
    dispatch
  ) => {
    dispatch(setGlogalLoading(true));
    if (route) {
        const response: FlightCorridorFromResponse | undefined = 
            await getApi.FlightCorridorAPI.buildFlightCorridor(route);
        if (response && response.data && response.data.geo) {
            var d = {}
            d['geo'] = response.data.geo
            dispatch(_setGeoMapData(response.data.geo))
        }
    }
    dispatch(setGlogalLoading(false));
  }


export const routeSelector = (store: AppState) => 
            store.flightCorridor.route;
export const autolocationSelector = (store: AppState) => 
            store.flightCorridor.autolocation;
export const geomap_dataSelector = (store: AppState) => 
            store.flightCorridor.geomap_data;
export const resizeCountSelector = (store: AppState) => 
            store.flightCorridor.resizeCount;
export const isFlightCorridorLoadingSelector = (store: AppState) => 
            store.flightCorridor.isFlightCorridorLoading;
export const validationErrorSelector = (store: AppState) => 
            store.flightCorridor.validationError;
export const validationSuccessSelector = (store: AppState) => 
            store.flightCorridor.validationSuccess;
export const errorRadiusSelector = (store: AppState) => 
            store.flightCorridor.error_radius;
export const submitButtonEnabledSelector = (store: AppState) => 
            store.flightCorridor.submitButtonEnabled;
export const initialOriginSelector = (store: AppState) => 
            store.flightCorridor.initialOrigin;

export const flightCorridorReducer = flightCorridorSlice.reducer;
