import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core'
import React from 'react';

export const Preloader = () => {

    return (
        <>
            <Grid container 
                    spacing={2}               
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center">
                <Grid item>
                    <CircularProgress />           
                </Grid>
                <Grid item>
                    <Typography color="textSecondary">
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
