import React from "react";
import { Grid } from '@material-ui/core'
import {useEffect, useState, useRef} from 'react';
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

// styles
import useStyles from "./styles";

// components
import Header from "components/header";
import { globalLoadingSelector } from "reducers/app-slice";
import { useSelector } from "react-redux";
import { Preloader } from "components/common/preloader";

import { FlightCorridorPage } from "../flight-corridor-page";
import { FlightMap } from "../flight-corridor-page/map-component";

import {resizeMap} from "reducers/flight-corridor-slice";


const useStylesPortrait = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      height: (portraitHeaderHeight) => 
          ["calc( 100vh - ", portraitHeaderHeight, "px )"].join(''),
      minHeight: '300px',
    },
  },
}));

const useStylesLandscape = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      height: (landscapeHeaderHeight) =>
        ["calc( 100vh - ", landscapeHeaderHeight, "px )"].join(''),
    },
  },
}));

export const MapPageLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalLoading = useSelector(globalLoadingSelector)

  const [portraitHeaderHeight, setPortraitHeight] = useState<any>(0);
  const [landscapeHeaderHeight, setLandscapeHeight] = useState<any>(0);

  const portraitMapClasses = useStylesPortrait(portraitHeaderHeight);
  const landscapeMapClasses = useStylesLandscape(landscapeHeaderHeight);

  const timelineLoaded = useRef<boolean>(false);

  useEffect(() => {
    const topSideBarElement = document.getElementById("top-side-bar");
    const headerHeight = document.getElementById("header-bar")

    const handleResize = () => {
      let portraitHeight: number;
      portraitHeight = topSideBarElement!.clientHeight +
                        headerHeight!.clientHeight +
                        //scroll-x size hack
                        (window.innerHeight -
                          document.documentElement.clientHeight);

      setPortraitHeight( portraitHeight )
      setLandscapeHeight( headerHeight!.clientHeight )

      dispatch(resizeMap());
    }

    const handleResizeDelayed = () => {   
      let timer = setTimeout(() => {
        handleResize()
      }, 50);

      return () => clearTimeout(timer)
    }

    if (topSideBarElement !== null && !timelineLoaded.current)
    {
      new ResizeObserver(handleResizeDelayed).observe(topSideBarElement);
      timelineLoaded.current = true;
    }
    else
    {
      handleResize();
    }

  }, [dispatch, globalLoading]);

  return (
    <div>
        <Header id="header-bar" />
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12} lg={4} id="top-side-bar"
            className={classes.topSideBar}>             
            <div style={{visibility:globalLoading?"hidden":"visible"}}
              className={classes.controlBlock} >
              <FlightCorridorPage />
            </div>
            <div className = {classes.globalLoading}>
                {globalLoading && <Preloader /> }
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg
            className={`${portraitMapClasses.root} ${landscapeMapClasses.root}`}>
            <FlightMap/>
          </Grid>
        </Grid>
    </div>
  );
}

