import {        
    CountryFromResponse, Country,
    CityFromResponse, City, 
    AirportFromResponse, Airport
} from "reducers/flight-corridor-slice";

export const saveTokenToLC = ({
    token_autorization,
    tokenExpirationAt,
}: {
    token_autorization: string;
    tokenExpirationAt: string;
}) => {
    localStorage.setItem("token_expires_at", tokenExpirationAt);
    localStorage.setItem("token_autorization", token_autorization);
};

export const getToken = () => {
    var auth_token = localStorage.getItem("token_autorization");
    var view_as_username = localStorage.getItem("view_as_username");
    if (view_as_username != null) {
        auth_token = auth_token + '';
        //var auth_res = {
        //    username: auth_token? auth_token: "",
        //    password: view_as_username
        //}
    } else {
        //var auth_res = {
        //    username: auth_token? auth_token : "",
        //    password: ''
        //}
    }
    return auth_token;
};

export const getTokenExpiresAt = () => {
    return localStorage.getItem("token_expires_at");
};

export const checkToken = (): boolean => {
    // return Boolean(getToken() && getTokenExpiresAt() && 
    // (new Date(getTokenExpiresAt() as string) > new Date()))
    return Boolean(getToken() && getTokenExpiresAt());
};

export const removeTokenFromLC = () => {
    localStorage.removeItem("token_expires_at");
    localStorage.removeItem("token_autorization");
    localStorage.removeItem('view_as_username')
};


export const transformCountryFromRequest = (
    countryResponse: CountryFromResponse
): Country => {
    return {
        id: countryResponse.el_country_dict_id,
        name: countryResponse.name,
        codeISO2: countryResponse.code_iso2
    }
}

export const transformCityFromRequest = (
    cityResponse: CityFromResponse
): City => {
    return {
        id: cityResponse.el_city_dict_id,
        name: cityResponse.name,
        country: transformCountryFromRequest(cityResponse.country),
        geo_coord: cityResponse.geo_coord
    }
}

export const transformAirportFromRequest = (
    airportResponse: AirportFromResponse
): Airport => {
    return {
        id: airportResponse.el_airport_dict_id,
        icao: airportResponse.icao_code,
        iata: airportResponse.iata_code,
        name: airportResponse.name,
        city: transformCityFromRequest(airportResponse.city),
        geo_coord: airportResponse.geo_coord
    }
}


export const parseMarkerLoc = (_prop) => {
    let location:string = "";

    if (_prop?.airport_name) {
        location = _prop?.airport_name + ' (' + 
            _prop?.icao_code + ')'
            //+ _prop?.city_name + ', ' +
            //_prop?.country_name
    }
    else if (_prop?.city_name) {
        location = _prop.city_name + ', ' + 
            _prop.country_name
    }

    return location;
};