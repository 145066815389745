import { getAxios } from "settings/axios";
import { FlightCorridorAPI } from "./flight-corridor-api";
import { setMock } from "./mock";

const axiosConfig = getAxios.getConfig();


export const checkMock = () => {
    if (process.env.REACT_APP_IS_MOCK === 'true') setMock(axiosConfig);
};

export const getApi = {
    FlightCorridorAPI: new FlightCorridorAPI(axiosConfig)
}