import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl'; // eslint-disable-line 
import * as MapboxGL from 'mapbox-gl';
import { parseMarkerLoc } from "utils";

import { useDispatch, useSelector } from "react-redux";
import { SxProps, Theme } from '@mui/material/styles';

import { 
  geomap_dataSelector,resizeCountSelector, 
  autolocationSelector, GeoPoint
} from "reducers/flight-corridor-slice"; 

mapboxgl.accessToken = 'pk.eyJ1IjoiaXNrYW5kcmUiLCJhIjoiY2pqbDZwd3l2MnFxcjNrcDJsaWd2bXlzZCJ9.KjH-I-0RkhVBIKKQ2Ad-7Q'

interface FlightMapProps {
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
}

var popup_start;
var popup_end;
var marker_start;
var marker_end;
var map_center = [15, 37];
var map_zoom = 1;
var max_zoom = 13;
var hoveredStateId: Number | null;
var clickStateId: Number | null;

function FlightMap({sx = [], ...props}: FlightMapProps) {
    const mapContainer = useRef(null);
    //const map = useRef();
    const map: any = useRef(null);
    const [lng, setLng] = useState(map_center[0]);
    const [lat, setLat] = useState(map_center[1]);
    const [zoom, setZoom] = useState(map_zoom);

    const geomap_data = useSelector(geomap_dataSelector);
    const resizeCount = useSelector(resizeCountSelector);
    const autolocation = useSelector(autolocationSelector)

    var popup = new MapboxGL.Popup({
        closeButtom: true,
        closeOnClick: true,
        transform:"none !important",
        offset: [0, -5],
    })

    const cleanMarkers = () => {
        if (marker_start) {
            marker_start.remove()
        }
        if (marker_end) {
            marker_end.remove()
        }
        if (popup_start && popup_start.isOpen()) {
            popup_start.remove()
        }
        if (popup_end && popup_end.isOpen()) {
            popup_end.remove()
        }
    }

    const setMapParams = (geojson) => {
      cleanMarkers();
      try{
          if (typeof map.current.getLayer('communes_borders') !== 'undefined') {
              map.current.removeLayer('communes_borders') 
          }
          if (typeof map.current.getLayer('communes_layer') !== 'undefined') {
              map.current.removeLayer('communes_layer') 
          }
          if (typeof map.current.getLayer('airport_points') !== 'undefined') {
              map.current.removeLayer('airport_points') 
          }
          if (typeof map.current.getSource('communes_source') !== 'undefined') {
              map.current.removeSource('communes_source') 
          }

          //state.popup_end.remove()
          //state.popup_start.remove()
          popup.remove()
      } catch {}

      map.current.addSource('communes_source', {
          'type': 'geojson',
          'data':geojson
        })

      map.current.addLayer({
        'type': 'line',
        'source': 'communes_source',
        'id':'communes_borders',
        'layout': {},
        'paint': {
          'line-color': '#a8a8a8',
          'line-width': 1
          }
      })

      map.current.addLayer({
        'id': 'communes_layer',
        'source': 'communes_source',
        'type': 'fill',
        'paint': {
          'fill-color':  '#48BFE3',
          'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              0.9,
              0.6
              ]
        }
      })

      map.current.addLayer({
        'id': 'airport_points',
        'type': 'circle',
        'source': 'communes_source',
        'paint': {
            'circle-color': '#04940d',
            'circle-stroke-width': {
                stops: [
                [0, 0.5],
                [8, 3],
                [10, 4],
                [13, 6],
                ],
            },
            'circle-stroke-color': '#ffffff',
            "circle-radius": {
                stops: [
                    [0, 2],
                    [3, 4],
                    [5, 6],
                    [8, 10],
                    [10, 15],
                    [13, 20],
                ],
            },
        },
        'filter': ['==', '$type', 'Point']
        });

        var description;

        if (geojson?.start_point != null && 
            geojson.start_point?.geometry != null && 
            geojson.start_point.geometry?.coordinates != null) {
                marker_start = new MapboxGL.Marker({
                        color:"#3245ab", scale: 1
                    })
                popup_start = new MapboxGL.Popup({
                    className: "startEndPointMarker",
                    closeButtom: false,
                    closeOnClick: false,
                    transform:"none !important",
                    offset: [0, 0],
                })
                let origin_prop = geojson.start_point?.properties;

                description = parseMarkerLoc(origin_prop);

                popup_start
                .setHTML(`<p>${description}</p>`)
                .addTo(map.current);

                marker_start
                .setLngLat(geojson?.start_point?.geometry?.coordinates )
                .addTo(map.current)
                .setPopup(popup_start);
        }

        if (geojson?.end_point != null && 
                geojson.end_point?.geometry != null && 
                geojson.end_point.geometry?.coordinates != null) {
                popup_end = new MapboxGL.Popup({
                    className: "startEndPointMarker",
                    closeButtom: false,
                    closeOnClick: false,
                    transform:"none !important",
                    offset: [0, 0],
                })
                marker_end = new MapboxGL.Marker(
                    {color:"#D80000", scale: 1}
                )
                let dest_prop = geojson.end_point?.properties;

                description = parseMarkerLoc(dest_prop);

                popup_end
                .setHTML(`<p>${description}</p>`)
                .addTo(map.current);

                marker_end
                .setLngLat(geojson?.end_point?.geometry?.coordinates )
                .addTo(map.current)
                .setPopup(popup_end);
        }


        map.current.on('mouseenter', 'airport_points', (evt) => {
          map.current.getCanvas().style.cursor = 'pointer';
          mapHover(evt)
          });

        map.current.on('mouseleave','airport_points', (evt) => {
          map.current.getCanvas().style.cursor = '';
          mapUnhover(evt)
        });

        map.current.on('preclick', (evt) => {
          if (popup) {
              popup.remove(); 
              clickStateId = null
          }
        });

        map.current.on('click','airport_points', (evt) => {
          clickStateId = showCorridorPopUp(evt)
        });


        map.current.flyTo({
          center: geojson?.center,
          zoom: geojson?.zoom
          });
        map_center = geojson?.center
        map_zoom = geojson?.zoom
    }


    const showCorridorPopUp = (evt:any) => {
      let payload = {"feature":{"properties":evt.features[0].properties,
      "geometry":evt.features[0].geometry,"id":evt.features[0].id},
      "lngLat":{"lng":evt.features[0].geometry.coordinates[0],
      "lat":evt.features[0].geometry.coordinates[1]}}

      let eventStateId: Number | null = payload.feature.id

      var coordinates = payload.feature.geometry.coordinates.slice();
      let airport_name = payload.feature.properties['name'];
      let icao = payload.feature.properties['icao'];
      var description = airport_name + 
                          ( icao ? ` (${icao.toString()})` : "" );
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(payload.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += payload.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      // Populate the popup and set its coordinates
      // based on the feature found.
      popup.setLngLat(payload.lngLat)
          .setHTML(`<p style="margin:0px">${description}</p>`)
          .on('open', e => {
      }).addTo(map.current);

      return eventStateId;
    }

    const mapHover = (evt:any) => {
        if (!clickStateId)
          hoveredStateId = showCorridorPopUp(evt)
    }
    
    const mapUnhover = (evt:any) => {
      if (hoveredStateId && !clickStateId) {
          if (popup) {
              popup.remove(); 
          }
      }
      hoveredStateId = null
    }

    useEffect(() => {
      if (map.current) return; // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/light-v10",
        center: [lng, lat],
        zoom: zoom,
        maxZoom: max_zoom
      });
    });
         
    useEffect(() => {
      if (!map.current) return; // wait for map to initialize
      map.current.on('move', () => {
        setLng(map.current.getCenter().lng.toFixed(4));
        setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
      });
    });

    useEffect(() => {
      if (geomap_data != null) {
        setMapParams(geomap_data)
      }
    }, [geomap_data]);

    useEffect(() => {
      if (autolocation?.city?.geo_coord != null) {
        var geo_loc: GeoPoint
        geo_loc = autolocation?.city?.geo_coord
          map.current.flyTo({
            center: [geo_loc['lon'], geo_loc['lat']],
            zoom: 2.5,
            speed: 0.2,
          })
        }
    }, [autolocation]);

    useEffect(() => {
      map.current.resize()
    }, [resizeCount]);

    return (
      <>
        <div style={{position:'absolute', zIndex: 10}}>
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        </div>
        <div ref={mapContainer} className="mapboxgl-map-container" />
      </>
    )
}

export { FlightMap };