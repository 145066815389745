


import MockAdapter from "axios-mock-adapter";
import { getUserInfoData, mockAuthLoginData, mockAuthLogoutData, 
        mockEditUserInfoSuccess, 
        mockUserListData } from "./mock-data";

export const setMock = (axios) => {
    var mock = new MockAdapter(axios);
    const status = 200;

    console.log('-------MOCK MODE--------')

    mock.onGet("/admin/get_users/").reply(status, mockUserListData);
    mock.onGet(/admin\/get_user\/?(.*)/).reply(status, getUserInfoData);
    mock.onPost("admin/edit_user/").reply(status, mockEditUserInfoSuccess);


    mock.onPost("logout/").reply(status, mockAuthLogoutData);

    mock.onPost("login/").reply(status, mockAuthLoginData);

    //allow requests that are not in mocks
    mock.onAny().passThrough();
}