import { v4 as uuid } from "uuid";

export const mockAuthLoginData = {
  data: {
    isLogged: true,
    token: {
      token: "mock_token",
      token_expiration_at: "21.07.2022",
    },
    isPasswordChanged: true,
  },
};

export const mockAuthLogoutData = {
  data: {
    isLoggedOut: true,
  },
};
export const mockEditUserInfoSuccess = { "data": { "isSuccess": true } }


export const getUserInfoData = {
  data: {
    user: {
      user_id: 90,
      name: "Alexander Komarov Test",
      email: "iskandre4@yahoo.co.uk",
      phone: "+352691556324",
      is_verified: true,
      is_phone_alerts: true,
      is_email_alerts: true,
      is_password_changed: true,
    },
  },
};

export const mockUserListData = {
  data: {
    users: [
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: uuid(),
        phone: "+352691556324",
        name: "Alexander Komarov Test",
        email: "iskandre4@yahoo.co.uk",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 0,
      },
      {
        id: 127,
        phone: "352661361318",
        name: null,
        email: null,
        is_verified: "True",
        is_phone_alerts: "None",
        is_email_alerts: "None",
        alerts_count: 0,
      },
      {
        id: 129,
        phone: "79818871418",
        name: "",
        email: "reenya577@gmail.com",
        is_verified: "True",
        is_phone_alerts: "True",
        is_email_alerts: "True",
        alerts_count: 10,
      },
      {
        id: 130,
        phone: "79639929318",
        name: null,
        email: null,
        is_verified: "True",
        is_phone_alerts: "None",
        is_email_alerts: "None",
        alerts_count: 0,
      },
    ],
  },
};
