// import needed components, functions and styles
import React, { FC } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainerStyled } from "./styles";
import { Notification } from "./notification";
import { ToastTypes } from "./types";


export const showToast = (type: ToastTypes = 'info', message: string = '') => {
  return toast(
    <Notification
      message={message}
      type={type}
    />,

  );
}

export const ToastNotification: FC = () => {
  return (<ToastContainerStyled
    position="bottom-right"
    closeOnClick={true}
    hideProgressBar={true}
    closeButton={false}
  />)
};

