import { useEffect, 
      //useState 
    } from "react";
import {
  Container,
  Grid,
  FormControl,
  TextField,
  Button,
  InputAdornment
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//import { Message } from "@material-ui/icons";
import { 
    routeSelector,
    //isFlightCorridorLoadingSelector,
    //validationErrorSelector,
    //validationSuccessSelector,
    setLocation,unsetLocation,
    setDefaultUserLocation,
    setRadiusValue,
    buildFlightCorridor, 
    //mapSelector,
    submitButtonEnabledSelector,
    errorRadiusSelector, setErrorRadius,
    setSubmitButton
} from "reducers/flight-corridor-slice";
import {ReactComponent as ReactLogo} from '../../images/mapbox_pointer_def.svg';
//import { FlightMap } from "./map-component"


import { useDispatch, useSelector } from "react-redux";
import { Preloader } from "components/common/preloader";
import AutocompleteSearchInput  from "components/common/autocomplete";
import { globalLoadingSelector } from "reducers/app-slice";
import { setGlogalLoading } from "reducers/app-slice";

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 300,
      width: "100%",
      maxWidth: 600,
    },
    formControlSelect: {
        minWidth: 300,
        width: "100%"
      },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: "100%",
      marginBottom: 15,
    },
    margin: {
      margin: theme.spacing(1),
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 100,
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    customErrTextStyle: theme.typography.caption,
    customErrMessage: {
      textAlign: 'left',
      marginTop: 3,
      margin: 0,
      paddingLeft: 43,
      color: theme.palette.error.main,
      marginLeft: 14,
      marginRight: 14,
    },
}));

export const FlightCorridorPage = () => {
  const classes = useStyles();

  const doLocationsMatch = ()  => {
    if (route?.origin != null && route?.destination != null ) {
      let origin_city_id = -1 as number;
      let destination_city_id = -1 as number;
      if (route?.origin?.airport != null) {
        origin_city_id = route?.origin?.airport.city.id
      }
      if (route?.origin?.city != null) {
        origin_city_id = route?.origin?.city.id
      }
      if (route?.destination?.airport != null) {
        destination_city_id = route?.destination?.airport.city.id
      }
      if (route?.destination?.city != null) {
        destination_city_id = route?.destination?.city.id
      }
      if (origin_city_id != -1 && destination_city_id != -1 ) {
        if (origin_city_id == destination_city_id) {
          dispatch(setSubmitButton(false))
          return 'Origin and Destination airports can not be within the same city'
        } else return null
      }
    }
    return null
  }
    
  const route = useSelector(routeSelector);
  //const isFlightCorridorLoading = 
  //      useSelector(isFlightCorridorLoadingSelector);
  //const map = useSelector(mapSelector)
  //const validationError = useSelector(validationErrorSelector)
  //const validationSuccess = useSelector(validationSuccessSelector)
  const error_radius = useSelector(errorRadiusSelector)
  const submitButtonEnabled = useSelector(submitButtonEnabledSelector)

  //const [flightOptions, setFlightOptions] = useState<typeof mockData>();
  const dispatch = useDispatch();
  const globalLoading = useSelector(globalLoadingSelector)
  const internal_ip = '1.1.1.1'
  
  useEffect(() => {
    dispatch(setDefaultUserLocation());
    //getUserLocation is needed in order to center the map
    //dispatch(getUserLocation())
  }, [dispatch]);

  return (
    <Container>
      <> 
        <FormControl className={classes.formControl}>
          <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          >
            <Grid item>
              <Grid container
                      direction="row"
                      spacing={2}
                      justifyContent="flex-start"
                      alignItems="center">
                <Grid item xs="auto" md="auto" lg="auto">
                  <ReactLogo fill="#3245ab" />
                </Grid>
                <Grid item xs md lg>
                  <AutocompleteSearchInput
                    //defaultValue={route?.origin ? route?.origin.name : ''}
                    //value = {route?.origin?.name? route?.origin?.name:{'name':''}}
                    //value = {route?.origin?.name? route?.origin?.name:null}
                    value = {route?.origin ? route?.origin : null}
                    internal_ip={internal_ip} id="origin" 
                    label = "From"
                    errorVal = {route?.origin?.errorHelperText != null ? true:null}
                    errorHelperText = {route?.origin?.errorHelperText}
                    disabled = {false}
                    locale='en'
                    onChangeValue={(event, newValue) => {
                        if (newValue.value == null) {
                          dispatch(unsetLocation(newValue.id,newValue.value))
                        } else {
                          dispatch(setLocation(newValue.id,newValue.value))
                        }
                        //newValue.idDestinations = destination.id
                        //this._handleSearchTermSubmit(event, newValue)
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container
                    direction="row"
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="center">
                <Grid item xs="auto" md="auto" lg="auto">
                  <ReactLogo fill="#D80000" />
                </Grid>
                <Grid item xs md lg>
                  <AutocompleteSearchInput internal_ip={internal_ip} 
                    defaultValue={route?.destination ? route?.destination.name : ''}
                    id="destination" 
                    label = "To"
                    //defaultValue={{'name':''}}
                    errorVal = {doLocationsMatch() != null? true: null}
                    errorHelperText = {''}
                    value = {route?.destination ? route?.destination : null}
                    disabled = {false}
                    locale='en'
                    onChangeValue={(event, newValue) => {
                        if (newValue.value == null) {
                          dispatch(unsetLocation(newValue.id,newValue.value))
                        } else {
                          dispatch(setLocation(newValue.id,newValue.value))
                        }
                        //newValue.idDestinations = destination.id
                        //this._handleSearchTermSubmit(event, newValue)
                    }}
                  />
                </Grid>   
              </Grid>
              {doLocationsMatch() &&
              <p className={`${classes.customErrTextStyle} ${classes.customErrMessage}`}>
                {doLocationsMatch()}</p>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Corridor Width"
                placeholder="(1-300)"
                InputProps={{
                  endAdornment: <InputAdornment position="end">km</InputAdornment>,
                }}
                id="radius"
                type="number"
                value = {route?.radius_km? route?.radius_km:''}
                variant="outlined"
                helperText={error_radius}
                error={error_radius !== '' ? true: false}
                //class="MuiInputBase-input MuiOutlinedInput-input 
                //MuiInputBase-inputMarginDense 
                //MuiOutlinedInput-inputMarginDense"
                onChange={(e) => {
                  var value = parseInt(e.target.value, 10);
                  if (value > 300) {
                    dispatch(setErrorRadius('Corridor Width can not exceed 300 km'))
                    value = 300
                  }
                  if (route?.radius_km === 300 && value < 300) {
                    dispatch(setErrorRadius(''))
                  }
                  dispatch(setRadiusValue(value))
                }}
              />
            </Grid> 
            <Grid item>
              <Button
                className={classes.formControl}
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitButtonEnabled === true? false:true}
                onClick={() => {
                  dispatch(buildFlightCorridor(route))
                }}
              >
                Directions
              </Button>
            </Grid>   
          </Grid>  
        </FormControl>
      </>
    </Container>
  );
};
