import React from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import { useSelector, useDispatch } from "react-redux";

import { 
  routeSelector,
  initialOriginSelector,
  setInitialOrigin
} from "reducers/flight-corridor-slice";

//todo: progress bar to implement for future
//import CircularProgress from '@material-ui/core/CircularProgress';
var flask_api_url = ''
if (localStorage.getItem("flask_api_url")) {
    flask_api_url = localStorage.getItem("flask_api_url");
} else if ((window)._env_) {
    localStorage.setItem("flask_api_url", (window)._env_.FLASK_API_URL);
    flask_api_url = (window)._env_.FLASK_API_URL
} else if (process.env && process.env.FLASK_API_URL) {
    flask_api_url = process.env.FLASK_API_URL
} else {
    console.log('flask_api_url not defined')
    flask_api_url = 'http://34.77.164.223:6001'
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const useStyles = makeStyles((theme) => ({
  root: {
      "& .Mui-error": {
        //color: "red"
      },
      "& .MuiFormHelperText-root": {
       //color: "red"
      },
      "& .MuiFormLabel-root.Mui-error" :{
      //color: theme.palette.primary.dark + "54"
      }
    },
  groupLabel:{
    top: "-8px",
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.primary.contrastText
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  /*
  helper: {
    position: "absolute",
    marginTop: "33px",
  },

  labeltext:{
      "& .Mui-error": {
        color: "black"
      },
      "& .MuiFormHelperText-root": {
           color: "black"
      }
  },
  */
  option: {
      // for the future use: when setting up the background for dropdown options
      /*
      '.MuiAutocomplete-option': {
        backgroundColor: 'green',
        borderColor: 'transparent',
      },
      */
      // Hover
      '&[data-focus="true"]': {
        //backgroundColor: 'red',
        borderColor: 'transparent',
      },
      //Selected
      '&[aria-selected="true"]': {
        //backgroundColor: 'blue',
        borderColor: 'transparent',
      },
      //Selected + Hover
      '&[aria-selected="true"]&[data-focus="true"]': {
        //backgroundColor: 'green',
        borderColor: 'transparent',
      },
      
    },
}));

const filterOptions = (options, state) => {
  let newOptions = [];
  options.forEach((el) => {
    //console.log('filtering options x=' + el?.name)
    if (el.airport != null || el.city != null) {
      newOptions.push(el)
    }
  })
  return newOptions
}

export default function AutocompleteSearchInput(props) {
        
  const classes = useStyles();
  //const [value, setValue] = React.useState(props.defaultValue);
  //const [value, setValue] = React.useState(null);
  const [value, setValue] = React.useState(props.value);
  const [inputValue, setInputValue] = React.useState(props.defaultValue);
  const [options, setOptions] = React.useState(props.defaultOptions?props.defaultOptions:[]);
  const [errorVal, setErrorVal] = React.useState(props.errorVal);
  const [disabled, setDisabled] = React.useState(props.disabled);
  const [errorHelperText, setErrorHelperText] = React.useState(props.errorHelperText);
  const [searchCount, setSearchCount] = React.useState(0);
  const dispatch = useDispatch();

  const initialOrigin = useSelector(initialOriginSelector);

  const route = useSelector(routeSelector);

  //var isInit = false
  //const [lastInputValue, setLastInputValue] = React.useState('');
  
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        const url = flask_api_url + '/find_location/?locale=' + 
                  props.locale + '&search=' + request.input;
        axios.get(url)
            .then(response => {
                  const results = response.data.data.options
                  if (results.length > 0) {
                    callback(results)
                  }
        }).catch(error => {
              console.log('Error ' + error);
            }); 
    }, 200),
    [
      //props.internal_ip, 
      props.locale
    ],
  );

  React.useEffect(() => {
    let active = true;
    setErrorVal(props.errorVal)
    setErrorHelperText(props.errorHelperText)
    
    //if (props.value == null && props.defaultValue != null) {
    //    setValue(props.defaultValue)
    //}
    
    if (props.disabled === true) {
        setDisabled(true)
    }
    
    //console.log('lastInputValue ' + lastInputValue)
    //console.log('inputValue ' + inputValue)
    //if (lastInputValue !== inputValue) {
        fetch({ input: inputValue }, (results) => {
          if (active) {

            if (results) {
                if (props.value) {
                  if (initialOrigin != null) {
                    setOptions([props.value, ...results])
                  }
                  /*
                  let flag = 0
                  if (results.filter(e => e.airport.id === 
                      props.value.airport.id).length > 0) {
                        setOptions(results)
                        flag = 1
                  } 
                  if (results.filter(e => e.city.id === 
                    props.value.city.id).length > 0) {
                      setOptions(results)
                      flag = 1
                  }
                  if (flag == 0) {
                    setOptions([props.value, ...results])
                  }
                  */
                  setOptions([...results])
                } else {
                  setOptions(results);
                }
                //setLastInputValue(inputValue)
            }
          }
        });    
    //} else {
    //    setOptions([])
    //}
    return () => {
      active = false;
    };
  }, [props.value, props.disabled, inputValue, fetch, props.defaultValue, 
          props.errorVal, 
          props.errorHelperText,
            props.defaultOptions, initialOrigin]);


  return (
    <Autocomplete
    className={classes.root}
      //id="custom-autocomplete"
      getOptionLabel={(option) => (typeof option === 'string' ? option : (option.name ? option.name : ""))}
      filterOptions={filterOptions}
      groupBy={(option) => capitalize(option.type)}
      options={options}
      autoComplete
      includeInputInList
      getOptionSelected={(option,val) => {
        //debugger
        if (val && val.city && option && option.city) {
          return val.city.id === option.city.el_city_dict_id
        }
        else if (val && val.airport && option && option.airport) {
          return val.airport.id === option.airport.el_airport_dict_id
        }

        return false
          }}
      value={props.value}
      disabled={disabled}
      classes={{option:classes.option, groupLabel:classes.groupLabel}}
      onChange={(event, newValue) => {
        //console.log('autocomplete onChange event ' + event + ' newValue ' + newValue)
        //setOptions(newValue ? [newValue, ...options] : options);
             
        setValue(newValue);
        props.onChangeValue(event, {'value':newValue, 'id':props.id})

        //setOptions(newValue ? [newValue, ...options] : options);
        //setOptions(newValue ? [newValue] : options);
      }}
      onInputChange={(event, newInputValue) => {
          //console.log('autocomplete onInputChange event ' + event + ' newInputValue ' 
          //        + newInputValue)
          
          setInputValue(newInputValue);
          if (searchCount > 0) {
            //dispatch(setInitialOrigin(null))
          }
          setSearchCount(searchCount + 1)
      }}
      /*InputLabelProps={{ error:false }}*/
      renderInput={(params) => (
        <TextField {...params} 
            error={errorVal === true ? true : false}
            helperText={errorHelperText}
            FormHelperTextProps={{ className: "textFieldHelpTextInside" }}
            /*InputLabelProps={{ error:false }}*/
            InputLabelProps={{ error:false }}
            label={props.label} variant="outlined" fullWidth />
      )}
    />
  );
}