import axios from "axios";
import { showToast } from "components/common/notifications";
import { getToken, removeTokenFromLC } from "utils";
import { history } from "../history";
import process from "process";

//const flask_api_url = process.env.REACT_APP_BACKEND_API_URL
//const flask_api_url = process.env.FLASK_API_URL
//console.log('flask_api_url ' + flask_api_url)
var flask_api_url = ''

if (localStorage.getItem("flask_api_url")) {
    flask_api_url = localStorage.getItem("flask_api_url") as string;
} else if ((window as any)._env_) {
    localStorage.setItem("flask_api_url", (window as any)._env_.FLASK_API_URL);
    flask_api_url = (window as any)._env_.FLASK_API_URL
} else if (process.env && process.env.FLASK_API_URL) {
    flask_api_url = process.env.FLASK_API_URL as string
} else {
    console.log('flask_api_url not defined')
    flask_api_url = 'http://34.77.164.223:6001'
}

const initialConfig = {
    baseURL: flask_api_url,
    headers: {
        'Content-Type': 'application/json',
    }
}

export const getAxios = {
    getConfig() {
        const axiosConfig = axios.create(initialConfig);
        axiosConfig.interceptors.request.use(request => {
            request.headers["Authorization"] = getToken();
            request.headers["Accept"]  = "application/json"
            var view_as_username = localStorage.getItem("view_as_username")
            if (view_as_username != null) {
                request.headers["view_as_username"] = view_as_username
            }
            return request;
        }, error => {
            console.error('invalid request', error);
            return Promise.reject(error);
        });

        axiosConfig.interceptors.response.use(response => {
            return response;
        }, error => {
            console.error(error);
            showToast('error', error.toString());
            
            if (error && error.response && (error.response.status===401)) {
                showToast('error', 'Session expired. Please login');
                removeTokenFromLC();
                history.push({
                    pathname: '/login'
                });
            }
            // return Promise.reject(error);
        });

        return axiosConfig;

    }
}